query GetSimpleMarkets {
  response: td_getSimpleMarkets {
    status
    error {
      code
      message
    }
    data {
      marketId
      marketName
      language {
        languageName
        cmsLocale
        languageCode
        locale
      }
    }
  }
}
