query($locale: SiteLocale) {
  header: testDrivePortalLayoutHeader(locale: $locale) {
    title
    logout
    menuSpacePortal
    menuTestDrivePortal
    menuVisitorMoment
    menuHandoverPortal
  }
}
