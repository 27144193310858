import { useCallback } from 'react';
import { useSnackbar as cwUseSnackbar } from '@polestar/component-warehouse-react';
import { datadogRum } from '@datadog/browser-rum';

interface UseSnackbar {
  // eslint-disable-next-line no-unused-vars
  addInfoSnackbar: (content?: string) => void;
  // eslint-disable-next-line no-unused-vars
  addErrorSnackbar: (content?: string) => void;
}

const useSnackbar = (): UseSnackbar => {
  const snackbar = cwUseSnackbar();

  const addInfoSnackbar = useCallback(
    (content?: string) => {
      snackbar.addSnackbar({
        closeOnClick: true,
        duration: 15000,
        content: content ?? 'Success.'
      });
    },
    [snackbar]
  );

  const addErrorSnackbar = useCallback(
    (content?: string) => {
      snackbar.addSnackbar({
        closeOnClick: true,
        duration: 15000,
        content: content ?? 'Something went wrong. Please try again later.',
        error: true
      });

      const error = new Error(content);
      datadogRum.addError(error);
    },
    [snackbar]
  );

  return { addInfoSnackbar, addErrorSnackbar };
};

export default useSnackbar;
