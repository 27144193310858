import React, { lazy } from 'react';
const StartView = lazy(() => import('~app/views/StartView'));
const BookingView = lazy(() => import('~app/views/BookingView'));
const MarketsView = lazy(() => import('~app/views/MarketsView'));
const VdmsView = lazy(() => import('~app/views/VdmsView'));
const VdmsCarCreateView = lazy(() => import('~app/views/VdmsCarCreateView'));
const VdmsCarView = lazy(() => import('~app/views/VdmsCarView'));
const VdmsCarUpdateView = lazy(() => import('~app/views/VdmsCarUpdateView'));
const HubsView = lazy(() => import('~app/views/HubsView'));
const CarsView = lazy(() => import('~app/views/CarsView'));
const CarView = lazy(() => import('~app/views/CarView'));
const CarUpdateView = lazy(() => import('~app/views/CarUpdateView'));
const MarketView = lazy(() => import('~app/views/MarketView'));
const HubCreateView = lazy(() => import('~app/views/HubCreateView'));
const HubView = lazy(() => import('~app/views/HubView'));
const HubUpdateView = lazy(() => import('~app/views/HubUpdateView'));
const EventsView = lazy(() => import('~app/views/EventsView'));
const EventCreateView = lazy(() => import('~app/views/EventCreateView'));
const EventUpdateDetailsView = lazy(() => import('~app/views/EventUpdateDetailsView'));
const EventUpdateCarsView = lazy(() => import('~app/views/EventUpdateCarsView'));
const EventUpdateScheduleView = lazy(() => import('~app/views/EventUpdateScheduleView'));
const EventUpdateScheduleTemplatesView = lazy(
  () => import('~app/views/EventUpdateScheduleTemplatesView')
);
const EventView = lazy(() => import('~app/views/EventView'));
const PlaygroundView = lazy(() => import('~app/views/PlaygroundView'));
const SupportView = lazy(() => import('~app/views/SupportView'));
const ScheduleTemplatesView = lazy(() => import('~app/views/ScheduleTemplatesView'));
const ScheduleTemplateCreateView = lazy(() => import('~app/views/ScheduleTemplateCreateView'));
const ScheduleTemplateView = lazy(() => import('~app/views/ScheduleTemplateView'));
const ScheduleTemplateUpdateView = lazy(() => import('~app/views/ScheduleTemplateUpdateView'));
const CustomerConsentView = lazy(() => import('~app/views/CustomerConsentView'));
const ReleaseNotesView = lazy(() => import('~app/views/ReleaseNotesView'));

/**
 * Array of route definition object
 * Each object will be map to react-router-dom <Route /> component and respect the following shape
 *
 * - name (string) : name of the route, internal reference used to generate a path for a given route
 * - path (string) : uri fragment, can contain variables in react-router-dom's fashion (e.g. /:locale/something/:id)
 * - exact (boolean) : wether to stricly match path or allow sub-routes (@see react-router-dom)
 * - view (string) : name of the view component to render
 * - sitemap (object) : public sitemap.xml informations (if set to undefined, will be ignored by the sitemap and not indexed)
 */
export default [
  {
    name: 'start',
    path: '/',
    exact: true,
    view: <StartView />
  },
  {
    name: 'booking',
    path: '/booking/:bookingId',
    exact: true,
    view: <BookingView />
  },
  {
    name: 'markets',
    path: '/markets',
    exact: true,
    view: <MarketsView />
  },
  {
    name: 'vdms',
    path: '/vdms',
    exact: true,
    view: <VdmsView />
  },
  {
    name: 'vdmsCarCreate',
    path: '/vdms/create',
    exact: true,
    view: <VdmsCarCreateView />
  },
  {
    name: 'vdmsCar',
    path: '/vdms/:vin',
    exact: true,
    view: <VdmsCarView />
  },
  {
    name: 'vdmsCarUpdate',
    path: '/vdms/:vin/update',
    exact: true,
    view: <VdmsCarUpdateView />
  },
  {
    name: 'hubs',
    path: '/hubs',
    exact: true,
    view: <HubsView />
  },
  {
    name: 'cars',
    path: '/cars',
    exact: true,
    view: <CarsView />
  },
  {
    name: 'car',
    path: '/cars/:carId',
    exact: true,
    view: <CarView />
  },
  {
    name: 'carUpdate',
    path: '/cars/:carId/update',
    exact: true,
    view: <CarUpdateView />
  },
  {
    name: 'market',
    path: '/markets/:marketId',
    exact: true,
    view: <MarketView />
  },
  {
    name: 'hubCreate',
    path: '/hubs/:marketId/create-hub',
    exact: true,
    view: <HubCreateView />
  },
  {
    name: 'hub',
    path: '/hubs/:marketId/:hubId',
    exact: true,
    view: <HubView />
  },
  {
    name: 'hubUpdate',
    path: '/hubs/:marketId/:hubId/update',
    exact: true,
    view: <HubUpdateView />
  },
  {
    name: 'events',
    path: '/events',
    exact: true,
    view: <EventsView />
  },
  {
    name: 'eventCreate',
    path: '/events/create',
    exact: false,
    view: <EventCreateView />
  },
  {
    name: 'eventUpdateDetails',
    path: '/events/update/details/:eventId',
    exact: false,
    view: <EventUpdateDetailsView />
  },
  {
    name: 'eventUpdateCars',
    path: '/events/update/cars/:eventId',
    exact: true,
    view: <EventUpdateCarsView />
  },
  {
    name: 'eventUpdateSchedule',
    path: '/events/update/schedule/:eventId',
    exact: true,
    view: <EventUpdateScheduleView />
  },
  {
    name: 'eventpilot',
    path: '/events/pilot/:eventId',
    exact: true,
    view: <EventUpdateScheduleTemplatesView />
  },
  {
    name: 'event',
    path: '/events/:eventId',
    exact: true,
    view: <EventView />
  },
  {
    name: 'playground',
    path: '/playground',
    exact: false,
    view: <PlaygroundView />
  },
  {
    name: 'support',
    path: '/support',
    exact: true,
    view: <SupportView />
  },
  {
    name: 'scheduleTemplates',
    path: '/schedule-templates',
    exact: true,
    view: <ScheduleTemplatesView />
  },
  {
    name: 'scheduleCreateTemplate',
    path: '/schedule-templates/create',
    exact: true,
    view: <ScheduleTemplateCreateView />
  },
  {
    name: 'scheduleTemplate',
    path: '/schedule-templates/:templateId',
    exact: true,
    view: <ScheduleTemplateView />
  },
  {
    name: 'scheduleTemplateUpdate',
    path: '/schedule-templates/:templateId/update',
    exact: true,
    view: <ScheduleTemplateUpdateView />
  },
  {
    name: 'customerConsent',
    path: '/customer-consent/:customerId',
    exact: true,
    view: <CustomerConsentView />
  },
  {
    name: 'releaseNotes',
    path: '/release-notes/*',
    exact: false,
    view: <ReleaseNotesView />
  }
];
