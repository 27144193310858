query ($locale: SiteLocale) {
  marketAndLanguage: testDrivePortalModelsMarketAndLanguage(locale: $locale) {
    marketNamesAustralia
    marketNamesAustria
    marketNamesBelgium
    marketNamesCanada
    marketNamesDenmark
    marketNamesFinland
    marketNamesGermany
    marketNamesKorea
    marketNamesLuxembourg
    marketNamesNetherlands
    marketNamesNorway
    marketNamesSweden
    marketNamesSwitzerland
    marketNamesUnitedKingdom
    marketNamesUnitedStates
    marketNamesSpain
    marketNamesIreland
    marketNamesPortugal
    marketNamesChina
    marketNamesItaly

    languagesSwedish
    languagesNorwegian
    languagesGerman
    languagesDutch
    languagesFrench
    languagesDanish
    languagesFinnish
    languagesEnglish
    languagesItalian
    languagesKorean
    languagesChinese
    languagesSpanish
    languagesPortuguese

    locationsHub
    locationsEvent
    locationsSpace
    locationsMarketParkingLot
  }

  spacePortalMarkets: allSpacePortalMarketTags(first: 100) {
    id
    marketId
  }

  general: testDrivePortalModelsGeneral(locale: $locale) {
    # Misc
    space
    atYours
    add
    remove
    hubs
    events
    back
    # Failed responses
    responsesGeneralFetchFailure
    responsesHubFetchFailure
    responsesHubUpdateFailure
    responsesHubCreateFailure
    # Success responses
    responsesHubUpdateSuccess
    responsesHubCreateSuccess
    # {} day
    dateAndTimeDay
    # {} days
    dateAndTimeDays
    # d
    dateAndTimeDayShort
    # {} week
    dateAndTimeWeek
    # {} weeks
    dateAndTimeWeeks
    # w
    dateAndTimeWeekShort
    # Start time delay
    dateAndTimeOffset
    # No delay
    dateAndTimeNoDelay
    # Second
    dateAndTimeSecond
    # Seconds
    dateAndTimeSeconds
    # s
    dateAndTimeSecondShort
    # Minute
    dateAndTimeMinute
    # Minutes
    dateAndTimeMinutes
    # m
    dateAndTimeMinuteShort
    # Hour
    dateAndTimeHour
    # Hours
    dateAndTimeHours
    # h
    dateAndTimeHourShort
  }

  select: testDrivePortalComponentsSelect(locale: $locale) {
    addressNotFound
    noOptions
    placeholderSelectAddress
    placeholderSelectDate
    placeholderSelectNumberOfDays
    placeholderSelectSpace
    placeholderSelectSpaceEmpty
    placeholderSelectTime
    placeholderSelectTimezone
  }

  bookingTypes: testDrivePortalModelsBookingType(locale: $locale) {
    bookingTypesTextReportedPerformed
    bookingTypesTextReportedNotPerformed
    bookingTypesTextMultiday
    bookingTypesEvent
    bookingTypesEventBooked
    bookingTypesBuffer
    bookingTypesDriveOut
    bookingTypesMaintenance
    bookingTypesManualTestdrive
    bookingTypesOther
    bookingTypesService
    bookingTypesTestdrive
    bookingTypesTimeslot
    bookingTypesTransition
    bookingTypesCancelledTestdrive
    bookingTypesEventTimeslot
    bookingTypesEventBlocked
    bookingTypesPreTestdrive
    bookingTypesPostTestdrive
    bookingTypesUnusedTime
    bookingTypesLunch
    bookingTypesTextOtherMaintenanceService
  }

  form: testDrivePortalComponentsForm(locale: $locale) {
    validationErrorBeforeDate
    validationErrorError
    validationErrorInvalidEmail
    validationErrorInvalidFirstName
    validationErrorInvalidLastName
    validationErrorInvalidPhone
    validationErrorInvalidTime
    validationErrorInvalidZipCode
    validationErrorMaxLength
    validationErrorMustBeGreaterThanDays
    validationErrorMustBeGreaterThanMinutes
    validationErrorMustBeLessThanDays
    validationErrorMustBeLessThanMinutes
    validationErrorNoAvailableCars
    validationErrorPhoneOrEmail
    validationErrorRequired
    validationErrorTodayOrLater
    validationErrorUnique
  }

  car: testDrivePortalModelsCar(locale: $locale) {
    carPowertrainDualMotor
    carPowertrainSingleMotor
    carPowertrainPerformance
    carPackagesPerformanceSoftwareUpgrade
    carRangeLong
    carRangeStandard
    carModelsPs1
    carModelsPs2
    carModelsPs3
    carModelsPs4
    carModelsPs1Long
    carModelsPs2Long
    carModelsPs3Long
    carModelsPs4Long
    carStatusAtEvent
    carStatusAtHub
    carStatusInTransitionIn
    carStatusInTransitionOut
    carStatusMarketParkingLot
    carStatusIncomingToMarket
    carStatusBroken
  }
}
