query($locale: SiteLocale) {
  errorView: testDrivePortalPagesErrorView(locale: $locale) {
    title
    subTitle

    textsTip
    textsContact
    textsContactEmail
    textsSignOut
    textsTryDifferentUser
  }
}
