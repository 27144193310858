import { PublicClientApplication, EventType } from '@azure/msal-browser';

import getRuntimeConfig from '~config';

const appConfig = getRuntimeConfig();

export const msalConfig = {
  auth: {
    clientId: appConfig.auth.clientId,
    authority: appConfig.auth.authority,
    redirectUri: appConfig.auth.redirectUri
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const loginRequest = {
  scopes: ['User.Read'],
  promp: 'select_account'
};

export const loginSilentRequest = {
  scopes: ['User.Read']
};

const pca = new PublicClientApplication(msalConfig);

if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
  // TODO: this logic is app dependant, check if this is correct for us
  pca.setActiveAccount(pca.getAllAccounts()[0] || null);
}

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

export default pca;
