import React, { Suspense } from 'react';
import ErrorBoundary from '~app/components/ErrorBoundary';
import Datadog from '~app/components/Datadog';
import { PageContent, Spinner } from '@polestar/component-warehouse-react';

const NoHeaderLayout = ({ children }) => (
  <>
    <Datadog />
    <PageContent>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>{children}</Suspense>
      </ErrorBoundary>
    </PageContent>
  </>
);

export default NoHeaderLayout;
