import { AppConfigData, StartConfigurationSessionCommand } from '@aws-sdk/client-appconfigdata';
import { fromWebToken } from '@aws-sdk/credential-providers';
import { useEffect, useState } from 'react';
import { awsOpenIdTokenForAnonymousUser } from '~app/lib/featureFlags';
import getRuntimeConfig from '~config';

const config = getRuntimeConfig();

export const getClient = async (webIdentityToken) =>
  new AppConfigData({
    region: config.appConfig.region,
    credentials: fromWebToken({
      roleArn: config.appConfig.unAuthRole,
      webIdentityToken: webIdentityToken,
      clientConfig: { region: config.appConfig.region }
    }),
    logger: {}
  });

export const startSession = async (client) => {
  const getSession = new StartConfigurationSessionCommand({
    ApplicationIdentifier: config.appConfig.appName,
    ConfigurationProfileIdentifier: config.appConfig.configName,
    EnvironmentIdentifier: config.stage
  });
  const sessionToken = await client.send(getSession);
  return sessionToken.InitialConfigurationToken || '';
};

export const useAppConfig = () => {
  const [appConfigClient, setAppConfigClient] = useState();
  const [initialConfigurationToken, setInitialConfigurationToken] = useState();

  useEffect(() => {
    const init = async () => {
      const cognitoToken = await awsOpenIdTokenForAnonymousUser();
      const client = await getClient(cognitoToken);
      setAppConfigClient(client);
    };
    init();
  }, []);

  useEffect(() => {
    if (!appConfigClient) return;

    const getInitialConfigurationToken = async () => {
      const token = await startSession(appConfigClient);
      setInitialConfigurationToken(token);
    };

    getInitialConfigurationToken();
  }, [appConfigClient]);

  return {
    appConfigClient,
    initialConfigurationToken
  };
};
