import {
  CognitoIdentityClient,
  GetIdCommand,
  GetOpenIdTokenCommand
} from '@aws-sdk/client-cognito-identity';

const region = import.meta.env.VITE_APPCONFIG_REGION;
const emptyLogger = {};

export const awsOpenIdTokenForAnonymousUser = async () => {
  const cognitoClient = new CognitoIdentityClient({ region, logger: emptyLogger });

  const identity = await cognitoClient.send(
    new GetIdCommand({ IdentityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID })
  );

  const token = await cognitoClient.send(
    new GetOpenIdTokenCommand({ IdentityId: identity.IdentityId })
  );

  return token?.Token;
};
