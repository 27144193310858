import React, { useRef } from 'react';

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client';

import { loginRequest, loginSilentRequest } from '~config/msal';

import omitTypeName from '~app/apollo/middlewares/omit_typename';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { useMsal } from '@azure/msal-react';

import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { datadogRum } from '@datadog/browser-rum';
import getRuntimeConfig from '~config';

const config = getRuntimeConfig();

const Apollo = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const tokenCacheTimeInSeconds = 10 * 60; // 10 Minutes
  const tokenCache = useRef({ token: null, expiryTimeStamp: null });

  const fetchToken = async () => {
    const account = instance.getActiveAccount();

    if (account && inProgress === 'none') {
      try {
        const result = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          forceRefresh: !config?.auth?.disableForceRefreshToken,
          account
        });

        return result.idToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(loginRequest);
        }
        datadogRum.addError(err);
      }
    } else if (!account && inProgress === 'none') {
      return instance.acquireTokenRedirect(loginRequest);
    }
    return null;
  };

  async function getToken() {
    const cachedtoken = tokenCache.current.token;
    const expiryTimeStamp = tokenCache.current.expiryTimeStamp;
    const currentTimeStamp = new Date().getTime() / 1000;

    if (cachedtoken && currentTimeStamp < expiryTimeStamp) return cachedtoken;

    const token = await fetchToken();

    tokenCache.current = {
      token,
      expiryTimeStamp: currentTimeStamp + tokenCacheTimeInSeconds
    };

    return token;
  }

  const azureAuth = setContext(async () => {
    const token = await getToken();

    return {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    };
  });

  const datoAuth = setContext(async () => ({
    headers: {
      Authorization: `Bearer ${config?.dato?.token}`
    }
  }));

  const isDatoReq = (operation) => operation.getContext()?.isDatoReq === true;

  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: { fetchPolicy: 'network-only', errorPolicy: 'all' },
      query: { fetchPolicy: 'network-only', errorPolicy: 'all' },
      mutate: { errorPolicy: 'all' }
    },
    addTypename: true,
    link: from([
      azureAuth,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) => {
            const error = new Error(message);
            datadogRum.addError(error);
            console.warn(
              `[GraphQL Error] Message: ${message}. Locations: ${locations}. Path: ${path}.`
            );
          });
        }
        if (networkError) {
          console.error(`[Network Error] ${networkError}`);
        }
      }),
      omitTypeName,
      split(
        isDatoReq,
        from([
          datoAuth,
          new HttpLink({
            uri: config?.dato?.baseUrl,
            useGETForQueries: false
          })
        ]),
        from([
          new HttpLink({
            uri: config.api.baseUrl,
            useGETForQueries: false
          })
        ])
      )
    ]),
    cache: new InMemoryCache({
      possibleTypes: {
        td_Booking: ['td_TestDriveBooking', 'td_GeneralBooking'],
        td_BookingMeta: ['td_GeneralBookingMeta', 'td_TestDriveBookingMeta']
      }
    })
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
