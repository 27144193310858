query($locale: SiteLocale) {
  menu: testDrivePortalComponentsMenu(locale: $locale) {
    menuItemMarkets
    menuItemHubs
    menuItemCars
    menuItemEvents
    menuItemCarManagement
    menuItemReleaseNotes
    menuItemSupport
  }
}
